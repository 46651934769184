import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { FacebookPost, FacebookReaction, PageInsight } from '@core/models';
import { FacebookQuery, PageContent, QueryParam } from '@shared/models';
import { Platform } from '@modules/social/models/platform';
import { FacebookAdCampaignResponse } from '@modules/social/models/facebook-ad-campaign';
import { FacebookAdset } from '@modules/social/models/facebook-adset';
import { FacebookAd } from '@modules/social/models/facebook-ad';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  private readonly baseUrl: string;
  private readonly v2BaseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/facebook`;
    this.v2BaseUrl = `${environment.baseUrl}/v2/facebook`;
  }

  getPageInsights(query: FacebookQuery): Observable<PageInsight[]> {
    const params = this.httpParams(query);
    return this.http.post<PageInsight[]>(`${this.v2BaseUrl}/page_insights`, params);
  }

  getSocialMediaOverview(query: FacebookQuery): Observable<PageInsight[]> {
    const params = this.httpParams(query);
    return this.http.post<PageInsight[]>(`${this.v2BaseUrl}/social_media_overview`, params);
  }

  getPostReaction(query: FacebookQuery): Observable<FacebookReaction> {
    const params = this.httpParams(query);
    return this.http.post<FacebookReaction>(`${this.v2BaseUrl}/post_insights`, params);
  }

  getPosts(query: FacebookQuery): Observable<FacebookPost[]> {
    const params = this.httpParams(query);
    return this.http.post<FacebookPost[]>(`${this.v2BaseUrl}/post`, params);
  }

  getPlatforms(): Observable<PageContent<Platform>> {
    return this.http.get<PageContent<Platform>>(`${this.baseUrl}/platform`);
  }

  getAdCampaign(hotelId: string, query: FacebookQuery): Observable<FacebookAdCampaignResponse> {
    const params = this.httpParams(query);
    if(hotelId){
      params['hotel_id'] = hotelId
    }

    return this.http.post<FacebookAdCampaignResponse>(`${this.v2BaseUrl}/campaign_meta_ads`, params );
  }

  getAdSets(campaignId: string, query: FacebookQuery): Observable<FacebookAdset[]> {
    const params = this.httpParams(query);
    if(campaignId){
      params['campaign_id'] = campaignId
    }

    return this.http.post<FacebookAdset[]>(`${this.v2BaseUrl}/adset_meta_ads`, params );
  }

  getAds(adSetId: string, query: FacebookQuery): Observable<FacebookAd[]> {
    const params = this.httpParams(query);
    if(adSetId){
      params['adset_id'] = adSetId
    }
    
    return this.http.post<FacebookAd[]>(`${this.v2BaseUrl}/get_meta_ads`, params );
  }

  private httpParams(query: FacebookQuery): { [key: string]: any } {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to
    }

    if (query?.enterprises && query.enterprises.length) {
      params.enterprises = query?.enterprises?.join(',');
    }

    if (query?.brands && query.brands.length) {
      params.brands = query?.brands?.join(',');
    }

    if (query?.hotels && query.hotels.length) {
      params.hotels = query?.hotels?.join(',');
    }

    if (query.platformId){
      params.platformId = query.platformId
    }

    return params;
  }
}
